import React from "react";
import {Box, Button, CircularProgress, TextField, Typography} from "@mui/material";
import axios from "axios";
import {LEE_API, TOOLPRESS_API} from "../config";
import {CustomAlert} from "./shared/CustomAlert";

export const FormPensiero = () => {
    const [pensiero, setPensiero] = React.useState('')
    const [sending, setSending] = React.useState(false)
    const [success, setSuccess] = React.useState(false)

    const sendPensiero = async (e) => {
        e.preventDefault()
        setSending(true)
        let session = ""
        await axios.get(`${LEE_API}/events/festival-delle-buone-notizie/current-session`)
            .then((res) => session = res.data)
            .then(async () => {
                await axios.post(`${LEE_API}/events/festival-delle-buone-notizie/sentences/${session}`,
                    {sentence: pensiero, table: 1, session: session, question: 0})
                    .then(res => {
                        setSuccess(true)
                    })
                    .catch(err => {
                    })
            })
        setPensiero('')
        setSending(false)
    }


    return (
        <Box>
            <CustomAlert open={success} setOpen={setSuccess} severity={'success'} message={'Pensiero inviato con successo'}/>
            <form onSubmit={sendPensiero}>
                <Typography variant={'h6'} sx={{pb: 1}}>
                    Inserisci qui il tuo pensiero
                </Typography>
                <TextField fullWidth
                           value={pensiero}
                           onChange={(e) => setPensiero(e.target.value)}
                           multiline placeholder={'Il tuo pensiero'} minRows={4}
                           sx={{
                               "& .MuiOutlinedInput-root": {
                                   color: "black",
                                   backgroundColor: 'rgba(246,212,198, 0.5)',
                                   borderRadius: '1rem',
                               },
                               '& fieldset': {
                                   borderColor: 'black',
                                   borderWidth: '3px !important',
                                   borderRadius: '1rem',
                               },
                               "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                   borderColor: "black",

                               },
                               "& .MuiInputLabel-outlined.Mui-focused": {
                                   color: "black"
                               },
                           }}
                />
                <Box sx={{textAlign: 'center'}}>
                    {sending ?
                        <CircularProgress/>
                        :
                        <Button type={'submit'} variant={'contained'} sx={{mt: 2, borderRadius: '5rem'}} size={'large'}>
                            Invia
                        </Button>
                    }
                </Box>
            </form>
        </Box>
    )
}