import React from "react";
import {Button, Grid, Typography} from "@mui/material";

const SentimentQuestion = ({tipo, sendAnswer, answer, theme}) => {
    console.log()
    return (
        <Grid container justifyContent={"center"} alignItems={"center"}
              sx={{textAlign: 'center', height: '100%', position: 'relative', pt:3}} spacing={5}>
            <Grid item xs={6} height={'50%'}>
                <Button onClick={() => sendAnswer('yes')} variant={'sentiment'} risposta={'daccordo'}
                        style={{opacity: answer === 'no' && '0.2', height:'9rem', width:'9rem', borderRadius:'5rem'}}>
                    <Typography variant={'h1'} fontWeight={'bold'}
                                color={tipo === 'clickTo' ? '#263238' : ''}>😄</Typography>
                </Button>
            </Grid>
            <Grid item xs={6} height={'50%'}>
                <Button onClick={() => sendAnswer('no')} variant={'sentiment'} risposta={'nonDaccordo'}
                        style={{opacity: answer === 'yes' && '0.2', height:'9rem', width:'9rem', borderRadius:'5rem'}}>
                    <Typography variant={'h1'} fontWeight={'bold'}>😞</Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

export default SentimentQuestion
