import React from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {Download} from "@mui/icons-material";

export const AltroQuestion = ({elementPlaying}) => {
    const bucket = "https://storage.googleapis.com/2ndstage-public/j53OATQYcgFe897K5eAg/report/"
  return(
      <Box sx={{pt:10}}>
          <Stack spacing={10}>
              <Typography variant={'h4'}>
                  Scarica l'output finale
              </Typography>
              <Box>
                  <Button startIcon={<Download/>} variant={'clap'} sx={{height:'4rem', color:'black', fontSize:'1.4rem'}} href={`${bucket}${elementPlaying.domanda}.pdf`} target={'_blank'} type={'download'}>
                        Scarica
                  </Button>
              </Box>
          </Stack>
      </Box>
  )
}