import './App.css';
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import pleshDark from "./theme/pleshDark";
import Home from "./pages/Home";
import Vote from "./pages/Vote";
import React from "react";
import {Box, CssBaseline, ThemeProvider} from "@mui/material";

function App() {
    return (
        <ThemeProvider theme={pleshDark}>
            <CssBaseline/>
            <Box sx={{position:'relative', minHeight:'100vh'}}>
                <div className="App">
                    <Routes>
                        <Route path='/' element={<Navigate to={'/vote/j53OATQYcgFe897K5eAg'}/>}/>
                        <Route path='/vote/:id' element={<Vote/>}/>
                    </Routes>
                </div>
            </Box>
        </ThemeProvider>
    );
}

export default App;
